import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import PostLink from "../../../../src/components/post-link";
import Departments from "../../../../src/data/departments.yml";
import Projects from "../../../../src/data/projects.yml";
import Members from "../../../../src/data/members.yml";
import AvekshaStyle from "../../../../src/components/aveksha.module.css";
import * as React from 'react';
export default {
  graphql,
  Link,
  Img,
  FontAwesomeIcon,
  faInstagram,
  faTwitter,
  PostLink,
  Departments,
  Projects,
  Members,
  AvekshaStyle,
  React
};