/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import SEO from "./seo"
import Styles from "./aveksha.module.css"

const Layout = ({ children, pageContext }) => {
  return (
    <div className={Styles.wrapper}>
      <SEO title={pageContext.frontmatter.title} description={pageContext.frontmatter.description}/>
      <main className={Styles.content}>{children}</main>
      <footer>
        
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
