import React from "react"
import { Link } from "gatsby"

import Styles from "./aveksha-post-link.module.css"

const AvekshaPostLink = ({ post }) => (
  <div>
    <Link to={post.frontmatter.path} className={Styles.linkWrapper}>
      <div>
        <div className={Styles.title}>{post.frontmatter.title}</div>
        <div className={Styles.date}>({post.frontmatter.date})</div>
      </div>
    </Link>
  </div>
)
export default AvekshaPostLink