import React from "react"
import { Link } from "gatsby"
const PostLink = ({ post }) => (
  <div>
    <Link to={post.frontmatter.path} className="no-decoration">
      <div className="post-link no-decoration">
      <h4 className="post-title no-decoration">{post.frontmatter.title} </h4>
      ({post.frontmatter.date})
      </div>
    </Link>
  </div>
)
export default PostLink