import { graphql, Link } from 'gatsby';
import AvekshaPostLink from "../../../../src/components/aveksha-post-link";
import Layout from "../../../../src/components/layout-aveksha";
import Styles from "../../../../src/components/aveksha.module.css";
import * as React from 'react';
export default {
  graphql,
  Link,
  AvekshaPostLink,
  Layout,
  Styles,
  React
};